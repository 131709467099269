/* eslint-disable import/no-anonymous-default-export */
import React, { useEffect, useState } from "react";

export const BookContext = React.createContext(null);

const COVER = [
    {
        key: "cover",
        index: 0,
    },
];

export default ({ children }) => {
    const [bookName, setBookName] = useState("");
    const [bookCover, setBookCover] = useState("");
    const [pages, setPages] = useState([]);
    const [scale, setScale] = useState(1);
    const [tabletMode, setTabletMode] = useState(window.innerWidth >= 1024 ? false : true);
    const [pageView, setPageView] = useState(COVER);

    const bookStores = {
        pageView: pageView,
        setPageView: setPageView,
        bookName: bookName,
        setBookName: setBookName,
        bookCover: bookCover,
        setBookCover: setBookCover,
        pages: pages,
        setPages: setPages,
        scale: scale,
        setScale: setScale,
        tabletMode: tabletMode,
        setTabletMode: setTabletMode,
    };

    return <BookContext.Provider value={bookStores}>{children}</BookContext.Provider>;
};
