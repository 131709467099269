/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { RouterProvider } from "react-router-dom";
import { router } from "../configs/routes";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Typography, message } from "antd";
import Audio from "./Audio";
import Video from "./Video";
import "../configs/i18n";
import { StoreContext } from "../stores";
import { checkValidSesson } from "../utils";

var pjson = require("../../package.json");
message.config({
    maxCount: 2,
});

const notRequiredLogin = ['login', 'error-page', 'notfound', 'session']

function Views() {
    const queryClient = new QueryClient();
    const { me, setMe } = useContext(StoreContext);
    const isLoginPage = notRequiredLogin.includes(window.location?.pathname?.split('/')[1])
    // check valid session user when f5 browser
    useEffect(() => {
        const getCurrentSession = async () => {
            await checkValidSesson(me, setMe);
        };
        if (!me && !isLoginPage) getCurrentSession();
    }, []);

    if (me || isLoginPage) {
        return (
            <QueryClientProvider client={queryClient}>
                <RouterProvider router={router} />
                <Audio />
                <Video />
                <Typography.Text className="app_version">version: {pjson.version}</Typography.Text>
            </QueryClientProvider>
        );
    }
}

export default Views;
