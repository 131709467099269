import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";

i18n.use(initReactI18next)
    .use(Backend)
    .use(LanguageDetector)
    .init({
        lng: localStorage.getItem("i18nextLng") || "vn",
        fallbackLng: localStorage.getItem("i18nextLng") || "vn",
        debug: false,
        defaultNS: "translation",
        interpolation: {
            escapeValue: true,
        },
        react: {
            useSuspense: false,
        },
    });

export { i18n };
