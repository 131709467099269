import axios from "axios";
import Cookies from "js-cookie";
import { returnLogin } from "../utils";
import { message } from "antd";

let access_token = Cookies.get("access_token");
axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

const AUTHENTICATION_FAIL = ["UR0015", "UR0014", "UR0013"];

export default axios.create({
    headers: {
        authorization: `Bearer ${access_token}`,
    },
});

export const handleFetchApi = async (method, path, data) => {
    if (!Cookies.get("access_token")) returnLogin();
    try {
        const response = await axios({
            headers: {
                authorization: `Bearer ${access_token}`,
            },
            method: method,
            url: `${process.env.REACT_APP_API_BASE_URL}/${path}`,
            data: data,
            mode: "cors",
        });

        // Authentication failed
        if (AUTHENTICATION_FAIL.includes(response?.data?.status)) {
            return returnLogin();
        }
        return response?.data;
    } catch (error) {
        if (error.code === "ERR_NETWORK") {
            window.location = "/error-page";
        }
        // if(error.code === 'ERR_BAD_REQUEST') {
        //     message.error("Phiên đăng nhập đã hết hạn!")
        //     setTimeout(() => {
        //         window.location = 'https://sso.cloudbook.vn/'
        //     }, 1000);
        // }
    }
};
