/* eslint-disable import/no-anonymous-default-export */
import React, { useEffect, useState } from "react";

export const ExerciseContext = React.createContext(null);

export default ({ children }) => {
    const [pending, setPending] = useState();
    const [matched, setMatched] = useState({});
    const [choice, setChoice] = useState({});
    const [fill, setFill] = useState({});
    const [writing, setWriting] = useState({});
    const [record, setRecord] = useState({});
    const [scale, setScale] = useState(1);
    const [exams, setExams] = useState([]);
    const [selecting, setSelecting] = useState();
    const [lines, setLines] = useState({});
    const [dragList, setDragList] = useState({});

    const stores = {
        pending: pending,
        setPending: setPending,
        matched: matched,
        setMatched: setMatched,
        fill: fill,
        setFill: setFill,
        choice: choice,
        setChoice: setChoice,
        writing: writing,
        setWriting: setWriting,
        record: record,
        setRecord: setRecord,
        scale: scale,
        setScale: setScale,
        exams: exams,
        setExams: setExams,
        setSelecting: setSelecting,
        selecting: selecting,
        lines: lines,
        setLines: setLines,
        dragList,
        setDragList
    };

    return <ExerciseContext.Provider value={stores}>{children}</ExerciseContext.Provider>;
};
