import React from "react";
import nodata from "../../assets/images/nodata.png";
import { t } from "i18next";
import { Typography } from "antd";

function NoData(props) {
    const { text, img, imageWidth } = props;
    return (
        <div className="nodata flex__center__center flex__column gap">
            <img style={{width : imageWidth ?? 150}} src={img || nodata} alt="nodata" />
            <Typography.Text type="secondary">{text || t("nodata")}</Typography.Text>
        </div>
    );
}

export default NoData;
