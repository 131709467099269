/* eslint-disable import/no-anonymous-default-export */
import React, { useEffect, useState } from "react";
import { ExerciseModeEnums, TrackingEnums, UserEnums } from "../configs/enums";
import { handleFetchApi } from "../configs/apiRequest";

const trackingUser = (type) => {
    let currentDate = new Date();
    handleFetchApi("post", "tracking-user", { type: type, execution_date: currentDate.toISOString().split("T")[0] });
};

export const StoreContext = React.createContext(null);

export default ({ children }) => {
    const [me, setMe] = useState();
    // maybe remove in next version
    const [loading, setLoading] = useState(false);
    const [exerciseMode, setExerciseMode] = useState();
    const [exerciseChildren, setExerciseChildren] = useState(new Map());
    const [selectedExercise, setSelectedExercise] = useState();
    const [assignmentExercises, setAssignmentExercises] = useState([]);
    const [displayAds, setDisplayAds] = useState(localStorage.getItem("displayAds") ?? 1);
    const [media, setMedia] = useState({});
    const [audioList, setAudioList] = useState();

    // tracking user every 1 minute
    useEffect(() => {
        if (me) {
            let interval = setInterval(() => trackingUser(TrackingEnums.ONLINE), 1000 * 60);
            return () => clearInterval(interval);
        }
    }, [me]);

    // tracking user doing exercise every 1 minute
    useEffect(() => {
        let interval;
        if (exerciseMode === ExerciseModeEnums.DOING) {
            trackingUser(TrackingEnums.DOING_TEST_ASSIGNMENT);
            interval = setInterval(() => trackingUser(TrackingEnums.DOING_TEST_ASSIGNMENT), 1000 * 60);
        }
        return () => clearInterval(interval);
    }, [exerciseMode]);

    const stores = {
        loading: loading,
        setLoading: setLoading,
        me: me,
        setMe: setMe,
        exerciseMode: exerciseMode,
        setExerciseMode: setExerciseMode,
        exerciseChildren: exerciseChildren,
        setExerciseChildren: setExerciseChildren,
        selectedExercise: selectedExercise,
        setSelectedExercise: setSelectedExercise,
        audioList: audioList,
        setAudioList: setAudioList,
        assignmentExercises: assignmentExercises,
        setAssignmentExercises: setAssignmentExercises,
        media: media,
        setMedia: setMedia,
        displayAds: displayAds,
        setDisplayAds: setDisplayAds
    };

    return <StoreContext.Provider value={stores}>{children}</StoreContext.Provider>;
};
