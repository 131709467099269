import { Suspense, lazy } from "react";
import { createBrowserRouter } from "react-router-dom";
import BookProvider from "../stores/bookStore";
import ExerciseProvider from "../stores/exerciseStore";
import Loading from "../components/Loading";
import SessionValidator from "../views/SessionValidator";

const Dashboard = lazy(() => import("../views/Dashboard"));
const Login = lazy(() => import("../views/Login"));
const Book = lazy(() => import("../views/Book"));
const Profile = lazy(() => import("../views/Profile"));
const NotFound = lazy(() => import("../views/NotFound"));
const ErrorPage = lazy(() => import("../views/Error"));
const ExerciseMode = lazy(() => import("../views/ExerciseMode"));
const ManageClasses = lazy(() => import("../views/Manage/ManageClasses"));
const ManageCourses = lazy(() => import("../views/Manage/ManageCourses"));
const ManageSchool = lazy(() => import("../views/Manage/ManageSchool"));
const ManageSchoolByAdmin = lazy(() => import("../views/Manage/ManageSchoolByAdmin"));
const ManageStudents = lazy(() => import("../views/Manage/ManageStudents"));
const ClassesCourse = lazy(() => import("../views/Manage/ClassesCourse"));
const StudentCourses = lazy(() => import("../views/Manage/StudentCourses"));
const StudentAssignments = lazy(() => import("../views/Manage/StudentAssignments"));
const ActivateBook = lazy(() => import("../views/ActivateBook"));
const ScoreReport = lazy(() => import("../views/ScoreReport"));
const StudentProfile = lazy(() => import("../views/Profile/StudentProfile"));

export const router = createBrowserRouter([
    {
        path: "/",
        element: (
            <Suspense fallback={<Loading />}>
                <Dashboard />
            </Suspense>
        ),
        errorElement: <ErrorPage />,
    },
    {
        path: "/login",
        element: (
            <Suspense fallback={<Loading />}>
                <Login />
            </Suspense>
        ),
        errorElement: <ErrorPage />,
    },
    // {
    //     path: "/profile",
    //     element: (
    //         <Suspense fallback={<Loading />}>
    //             <Profile />
    //         </Suspense>
    //     ),
    //     errorElement: <ErrorPage />,
    // },
    {
        path: "/book/:book_id",
        element: (
            <BookProvider>
                <Suspense fallback={<Loading />}>
                    <Book />
                </Suspense>
            </BookProvider>
        ),
        errorElement: <ErrorPage />,
    },
    {
        path: "/manage",
        element: (
            <Suspense fallback={<Loading />}>
                <ManageCourses />
            </Suspense>
        ),
        errorElement: <ErrorPage />,
    },
    {
        path: "/manage/courses",
        element: (
            <Suspense fallback={<Loading />}>
                <ManageCourses />
            </Suspense>
        ),
        errorElement: <ErrorPage />,
    },
    {
        path: "/manage/school",
        element: (
            <Suspense fallback={<Loading />}>
                <ManageSchool />
            </Suspense>
        ),
        errorElement: <ErrorPage />,
    },
    {
        path: "/manage/school/:school_id",
        element: (
            <Suspense fallback={<Loading />}>
                <ManageSchool />
            </Suspense>
        ),
        errorElement: <ErrorPage />,
    },
    {
        path: "/manage/school-by-admin",
        element: (
            <Suspense fallback={<Loading />}>
                <ManageSchoolByAdmin />
            </Suspense>
        ),
        errorElement: <ErrorPage />,
    },
    {
        path: "/manage/students",
        element: (
            <Suspense fallback={<Loading />}>
                <ManageStudents />
            </Suspense>
        ),
        errorElement: <ErrorPage />,
    },
    {
        path: "/manage/course/:course_id",
        element: (
            <Suspense fallback={<Loading />}>
                <ClassesCourse />
            </Suspense>
        ),
        errorElement: <ErrorPage />,
    },
    {
        path: "/manage/course/:course_id/class/:class_id",
        element: (
            <Suspense fallback={<Loading />}>
                <ManageClasses />
            </Suspense>
        ),
        errorElement: <ErrorPage />,
    },
    {
        path: "/student/courses",
        element: (
            <Suspense fallback={<Loading />}>
                <StudentCourses />
            </Suspense>
        ),
        errorElement: <ErrorPage />,
    },
    {
        path: "/student/TEST",
        element: (
            <Suspense fallback={<Loading />}>
                <StudentAssignments />
            </Suspense>
        ),
        errorElement: <ErrorPage />,
    },
    {
        path: "/student/NORMAL",
        element: (
            <Suspense fallback={<Loading />}>
                <StudentAssignments />
            </Suspense>
        ),
        errorElement: <ErrorPage />,
    },
    {
        path: "/student/profile",
        element: (
            <Suspense fallback={<Loading />}>
                <StudentProfile />
            </Suspense>
        ),
        errorElement: <ErrorPage />,
    },
    {
        path: "/activate-book",
        element: (
            <Suspense fallback={<Loading />}>
                <ActivateBook />
            </Suspense>
        ),
        errorElement: <ErrorPage />,
    },

    // Exercise
    {
        path: "/exercise/:exercise_id",
        element: (
            <ExerciseProvider>
                <Suspense fallback={<Loading />}>
                    <ExerciseMode />
                </Suspense>
            </ExerciseProvider>
        ),
        // errorElement: <ErrorPage />,
    },
    {
        path: "/assignment/:exercise_id",
        element: (
            <ExerciseProvider>
                <Suspense fallback={<Loading />}>
                    <ExerciseMode />
                </Suspense>
            </ExerciseProvider>
        ),
        // errorElement: e => {
        //     console.log('e', e);
        //     return <ErrorPage />
        // },
    },
    {
        path: "/score_report",
        element: (
            <ExerciseProvider>
                <Suspense fallback={<Loading />}>
                    <ScoreReport />
                </Suspense>
            </ExerciseProvider>
        ),
        errorElement: <ErrorPage />,
    },
    {
        path: "/error-page",
        element: <ErrorPage />,
    },
    {
        path: "/session/:session_id",
        element: <SessionValidator />,
    },
    {
        path: "/*",
        element: <NotFound />,
    },
]);
