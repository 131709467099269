import React from "react";
import ReactDOM from "react-dom/client";
import "./index.less";
import Views from "./views";
import reportWebVitals from "./reportWebVitals";
import StoreProvider from "./stores";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <StoreProvider>
        <Views />
    </StoreProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
